.info-card {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  padding:  10px 18px;
  min-width: 180px;
  max-width: 100%;

  &:hover {
    transform: scale(1.05);
  }

  transition: transform 0.3s ease-in-out;

  .info-card-detail {
    display: flex;
    //   flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    .heading {
      font-weight: 800;
      font-size: 15px;
    }

    .title {
      font-size: 17px;
      font-weight: 500;
      color: #6f767e;
      min-width: max-content;
    }
  }
}

// @media (max-width: 1550px) {
//   .info-card {
//       flex: 1;
//   }
// }
