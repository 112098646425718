// @import 'variables';

// .form-group {
//   display: flex;
//   flex-direction: column;
// }

// label,
// .label-sm {
//   color: $dark10;
//   font-size: 0.85rem;
//   font-weight: 400 !important;
//   margin-bottom: 12px;
//   line-height: 1.25rem;
// }

// .label-sm {
//   color: $dark12;
//   font-size: 12px !important;
//   font-weight: 400 !important;
// }

// input,
// textarea {
//   border-radius: 4px;
//   // background: $lightestGrey;
//   border: 1px solid #dee3e7;
//   // flex: 1;
//   font-size: 14px;
//   // padding: 16px 20px;
//   transition: box-shadow 0.2s ease;
//   /* Add transition for box-shadow */
//   font-weight: 500 !important;
//   color: $dark !important;
// }

// // input:-webkit-autofill {
// //   background-color: $lightestGrey !important;
// // }

// textarea {
//   flex: none;
//   min-height: 80px;
//   max-height: 400px;
//   background-color: $white;
// }

// .input-div {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: $light;
//   border-radius: 10px;
//   padding: 26px;
//   min-height: 48px;
// }

// input:focus,
// textarea:focus {
//   // border: 1px solid #3D9BE9;
//   // box-shadow: 0 0 10px rgba(61, 155, 233, 0.5);
//   // background: none;
//   border: 1px solid $primary;
//   background-color: $white;
//   outline: none;
// }

// .input-group {
//   width: 100%;
//   border-radius: 10px;
// }

// // .input-group-append {
// //   height: 48px;
// //   flex-shrink: 0;
// //   padding: 12px 20px;
// //   background: $lightestGrey;
// //   border-radius: 0px 10px 10px 0px;
// //   align-items: center;
// //   justify-content: center;
// // }

// // .input-group .input-group-append {
// //   border-left: 0 !important;
// // }

// // Form -switch

// .add-page-switch {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-radius: 10px;
//   border: 1px solid $light;
//   background: $white;
//   padding: 10px 20px;
// }

// .form-switch {
//   display: flex;
//   align-items: center;
// }

// .form-switch .form-check-input {
//   width: 48px;
//   margin: 0;
//   padding: 0 14px;
//   height: 28px;
//   // background-color: $lightestGrey;
//   border: none;
//   --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' fill='%23fff' filter='drop-shadow(1px 1px 1px rgba(0,0,0,0.3))'%3e%3ccircle r='3'/%3e%3c/svg%3e");
// }

// .form-check-input:checked {
//   background-color: $green !important;
//   border-color: $green !important;
// }

// .form-check-input:focus {
//   box-shadow: none;
// }

// .form-switch .form-check-input:focus {
//   --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' fill='%23fff' filter='drop-shadow(1px 1px 1px rgba(0,0,0,0.3))'%3e%3ccircle r='3'/%3e%3c/svg%3e");
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
// }

// .input-container {
//   position: relative;
// }

// .primary-input {
//   width: 100%;
//   padding: 8px 16px;
//   border-radius: 8px;
//   background-color: white;
//   border: 1px solid #dee3e7;
//   height: 60px;
//   padding-bottom: 4px;
//   padding-top: 20px;
//   font-size: 16px;
//   font-weight: 500;
//   color: #1b242c;
// }

// .input-placeholder {
//   position: absolute;
//   top: 20px;
//   left: 45px;
//   pointer-events: none;
//   transition: 0.2s ease all;
// }
// .primary-input:focus + .input-placeholder,
// .primary-input:not(:placeholder-shown) + .input-placeholder {
//   top: 8px;
//   left: 40px;
//   font-size: 12px;
//   font-weight: 400;
//   content: 'varun Reddy';
//   position: absolute;
// }

// .error-input {
//   background: #c5343412 !important;
//   border: 1px solid var(--Red, #c53434) !important;
//   input {
//     background: #c5343403 !important;
//   }
// }
// .error-input-message {
//   color: #bc204b;
//   font-size: 14px;
//   font-weight: 500;
//   margin-top: 8px;
// }

// input {
//   &:focus {
//     outline: none;
//     border: 1px solid var(--Brand-Color, #146ef5);
//   }
//   &:active {
//     outline: none;
//     border: 1px solid var(--Brand-Color, #146ef5);
//   }
// }

// .primary-input-small {
//   width: 100%;
//   border-radius: 4px;
//   border: 1px solid #dee3e7;
//   background-color: white;
//   height: 46px;
//   padding: 8px 14px;
//   font-size: 16px;
//   font-weight: 500;
//   color: #1b242c;
//   padding-bottom: 4px;
//   padding-top: 16px;
// }

// .primary-input-small:focus + .input-placeholder-small,
// .primary-input-small:not(:placeholder-shown) + .input-placeholder-small {
//   top: 0px;
//   left: 14px;
//   font-size: 12px !important;
//   font-weight: 400;
// }

// .input-placeholder-small {
//   position: absolute;
//   top: 13px;
//   left: 18px;
//   pointer-events: none;
//   transition: 0.2s ease all;
// }
// .input-placeholder-small {
//   &.active {
//     top: 0px;
//     left: 14px;
//     font-size: 12px !important;
//     font-weight: 400;
//   }
// }

// .textarea-input {
//   position: relative;
//   .text-area {
//     width: 100%;
//     border-radius: 4px;
//     border: 1px solid #dee3e7;
//     &:focus {
//       border: 1px solid var(--Brand-Color, #146ef5);
//     }
//   }
// }

// input::-ms-reveal,
// input::-ms-clear {
//   display: none !important;
// }

@import 'variables';

.form-group {
  display: flex;
  flex-direction: column;
}

label,
.label-sm {
  color: $dark10;
  font-size: 0.85rem;
  font-weight: 400 !important;
  margin-bottom: 12px;
  line-height: 1.25rem;
}

.label-sm {
  color: $dark12;
  font-size: 12px !important;
  font-weight: 400 !important;
}

input:disabled{
  background-color: hsl(0, 0%, 95%);
  pointer-events: nones;
  color: hsl(0, 0%, 60%) !important;

}

input,
textarea {
  border-radius: 4px;
  // background: $lightestGrey;
  border: 1px solid #dee3e7;
  // flex: 1;
  font-size: 14px;
  // padding: 16px 20px;
  transition: box-shadow 0.2s ease;
  /* Add transition for box-shadow */
  font-weight: 500 !important;
  color: $dark !important;
}

// input:-webkit-autofill {
//   background-color: $lightestGrey !important;
// }

textarea {
  flex: none;
  min-height: 80px;
  max-height: 400px;
  background-color: $white;
}

.input-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light;
  border-radius: 10px;
  padding: 26px;
  min-height: 48px;
}

input:focus,
textarea:focus {
  // border: 1px solid #3D9BE9;
  // box-shadow: 0 0 10px rgba(61, 155, 233, 0.5);
  // background: none;
  border: 1px solid $primary;
  background-color: $white;
  outline: none;
}

.input-group {
  width: 100%;
  border-radius: 10px;
}

// .input-group-append {
//   height: 48px;
//   flex-shrink: 0;
//   padding: 12px 20px;
//   background: $lightestGrey;
//   border-radius: 0px 10px 10px 0px;
//   align-items: center;
//   justify-content: center;
// }

// .input-group .input-group-append {
//   border-left: 0 !important;
// }

// Form -switch

.add-page-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $light;
  background: $white;
  padding: 10px 20px;
}

.form-switch {
  display: flex;
  align-items: center;
}

.form-switch .form-check-input {
  width: 48px;
  margin: 0;
  padding: 0 14px;
  height: 28px;
  // background-color: $lightestGrey;
  border: none;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' fill='%23fff' filter='drop-shadow(1px 1px 1px rgba(0,0,0,0.3))'%3e%3ccircle r='3'/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: $green !important;
  border-color: $green !important;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' fill='%23fff' filter='drop-shadow(1px 1px 1px rgba(0,0,0,0.3))'%3e%3ccircle r='3'/%3e%3c/svg%3e");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-container {
  position: relative;
}

.primary-input {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #dee3e7;
  height: 60px;
  padding-bottom: 4px;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #1b242c;
}

.input-placeholder {
  position: absolute;
  top: 20px;
  left: 45px;
  pointer-events: none;
  transition: 0.2s ease all;
}
.primary-input:focus + .input-placeholder,
.primary-input:not(:placeholder-shown) + .input-placeholder {
  top: 8px;
  left: 40px;
  font-size: 12px;
  font-weight: 400;
  content: 'varun Reddy';
  position: absolute;
}

.error-input {
  background: #c5343412 !important;
  border: 1px solid var(--Red, #c53434) !important;
  input {
    background: #c5343403 !important;
  }
}
.error-input-message {
  color: #bc204b;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

input {
  &:focus {
    outline: none;
    border: 1px solid var(--Brand-Color, #146ef5);
  }
  &:active {
    outline: none;
    border: 1px solid var(--Brand-Color, #146ef5);
  }
}

.primary-input-small {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dee3e7;
  background-color: white;
  height: 46px;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 500;
  color: #1b242c;
  padding-bottom: 4px;
  padding-top: 16px;
}

.primary-input-small:focus + .input-placeholder-small,
.primary-input-small:not(:placeholder-shown) + .input-placeholder-small {
  top: 0px;
  left: 14px;
  font-size: 12px !important;
  font-weight: 400;
}

.input-placeholder-small {
  position: absolute;
  top: 13px;
  left: 18px;
  pointer-events: none;
  transition: 0.2s ease all;
}
.input-placeholder-small {
  &.active {
    top: 0px;
    left: 14px;
    font-size: 12px !important;
    font-weight: 400;
  }
}

.textarea-input {
  position: relative;
  .text-area {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dee3e7;
    &:focus {
      border: 1px solid var(--Brand-Color, #146ef5);
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}