@import "../../../../../assets/styles/variables.scss";

.AddSignerStyle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.buttonWrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.resetAdd {
  display: flex;
  gap: 20px;
}
.imagesNameStyle {
  border: 1px solid $side_grey;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}
.closeIcon {
  position: absolute;
  top: 15%;
  right: 4%;
  scale: 0.8;
  cursor: pointer;
}
.imagesName {
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 90%;
}
.view_selected_image{
  width: 100%;
}