
// @import "../variables";
.user-select-container {
  position: relative;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.user-select-dropdown-menu {
  box-sizing: content-box;
  position: absolute;
  border-radius: 6px;
  top: calc(100% + 10px);
  width: 97%;
  z-index: 1000;
  border: 1px solid #ebeef6;
  background: #fff;
  box-shadow: 0px 6px 6px 0px rgba(30, 39, 59, 0.04);
  flex-shrink: 0;
  max-height: 230px; /* Set your desired maximum height */
  overflow-y: auto; /* Add this to enable scrolling when the content exceeds max-height */
  width: 300px;
}
.user-select-dropdown-menu.adjust-up {
  bottom: calc(100% + 10px);
  top: auto;
}

.user-select-dropdown-list {
  padding-left: 0 !important;
  list-style: none;
  margin: 10px 0;
}

.user-select-dropdown-listItem,
.filter-option {
  color: #1e273b;
//   font-family: $font-plus;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 8px 15px;
  cursor: pointer;
}

.user-select-dropdown-listItem:hover,
.filter-option:hover {
  background: #f6f8fc;
}

/* CountrySelectComponent.css */
.country-select-dropdown-menu {
  width: 110px !important;
}

.tag-couter {
    background-color: #fff;
    border-radius: 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 508;
    margin-left: 10px;
    height: 22px;
    color: #3B424A;
  }

  .form-floating>label{
    top: -6px !important;
    padding-top: 20px !important;
  }

@media (min-width: 200px) and (max-width: 900px) {
  .user-select-dropdown-menu {
    width: 100% !important;
  }
}
