.card-container-in {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .card-in {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .card-title-in {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
  
  .card-actions-in {
    display: flex;
    justify-content: space-between;
  }
  
  @media (min-width: 768px) {
    .card-in {
      width: calc(50% - 16px); /* Two cards per row */
    }
  }
  
  @media (min-width: 1024px) {
    .card-in {
      width: calc(33.33% - 16px); /* Three cards per row */
    }
  }
  