.custom-option {
    display: flex;
    justify-content: space-between;
  }

  .custom-option .tag {
    background-color: #fff;
    border-radius: 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 508;
    margin-left: 10px;
    height: 22px;
    color: #3B424A;
  }
  
  .Dropdowm_switching_console{
    position: fixed;
    // bottom: 14px;
      width: 100%;
      // margin: 4px 10px;
    background: none;
    .select__control{
      padding: 6px 16px;
      background: none;
      color: #dee3e7;
    }
    
    .input-placeholder-small{
      color: #fff;
    }
    
    .select__control--menu-is-open{
      .select__indicator{
        scale: -1;
      }
  
      
    }
    .select__single-value  {
      color: #dee3e7;
      font-size: 13px;
    }
    .select__indicator{
      svg{
      fill: #dee3e7;
      }
    }
  }