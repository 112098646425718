.table-div{
    margin-top: 18px;
}

.MuiDataGrid-topContainer::after{
    background-color: #DEE3E7 !important;
}

.MuiDataGrid-row{
    --rowBordercolor : #DEE3E7 !important;
}