.label_view{
    color:  #555F6D;
    font-size: 14px !important;
    font-weight: 400 !important;
    min-width: max-content;
  }
  .label_description{
    color: #1B242C;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

.view_selected_image{
  max-width: 700px;
  margin-top: 20px;
}