/* Overlay */
.random-overlay-class {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align modal at the bottom */
    z-index: 1000;
  }
  
  /* Modal content */
  .random-modal-content-class {
    background-color: white;
    width: 100%;
    // max-width: 500px; /* Max width for the modal */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  /* Header section */
  .random-modal-header-class {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Body section */
  .random-modal-body-class {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between date pickers */
  }
  
  /* Label styles */
  .random-modal-body-class label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  /* Footer section */
  .random-modal-footer-class {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
  }

