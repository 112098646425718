@import '../../../assets/styles/variables.scss';

.no-content-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 6%;
    padding-bottom: 5%;

    img { 
        height: 11rem;
    }
}

.no-content-title {
    color: $dark14;
    text-align: center;
    font-size: 24px; /* 18px / 16px */
    font-weight: 600;
    line-height: 32px; /* 20px / 16px */
    margin-bottom: 6px; /* 12px / 16px */
}

.no-content-subtitle {
    color: #697184;
    font-size: 16px; /* 14px / 16px */
    font-weight: 400;
    max-width: 416px;
    text-align: center;
}

.no-content-circle {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    display: flex;
    background-color: $light;
    align-items: center;
    justify-content: center;
    i {
        font-size: 32px;
        color: $dark;
    }
}