// @import 'Typography.scss';
// @import 'inputs.scss';
// @import 'variables.scss';
// @import 'button.scss';
// @import 'anchor.scss';

// //common
// .HeadingStyle {
//   font-size: 24px;
//   line-height: 32px;
//   font-weight: 600;
//   font-family: $font-Instrument;
//   color: var(--Dark-10, #555F6D) !important;
// }
// .HeadingSubStyle{
//   font-size: 18px;
//   line-height: 32px;
//   font-weight: 600;
//   font-family: $font-Instrument;
// }
// .addHeaderStyle {
//   font-size: 24px;
//   font-weight: 600;
//   line-height: 32px;
//   color: #1A1D1F !important;
// }
// .headingSearchWrap {
//   display: flex;
//   justify-content: space-between;
// }
// .valueStyle {
//   font-size: 14px;
//   font-weight: 400;
//   font-family: $font-Instrument;
//   color: #1b242c;
// }
// .searchButton {
//   display: flex;
//   align-items: center;
//   gap: 20px;
// }

// .buttonWrap {
//   margin-top: 20px;
//   display: flex;
//   justify-content: space-between;
// }
// .resetAdd {
//   display: flex;
//   gap: 20px;
// }

// // Toast React Hot Toast Customization
// .go2344853693:after {
//   border-color: #1e273b !important ;
// }

// .go2534082608:after,
// .go2534082608:before {
//   background: rgb(190, 15, 0) !important;
// }

// .go2344853693:after {
//   border-color: #83bf6e !important;
// }

// .go2344853693,
// .go2534082608 {
//   background-color: #fff !important;
// }

// ::-moz-selection {
//   /* Code for Firefox */
// }

// ::selection {
// }

// //view
// .view-label {
//   font-size: 12px;
//   font-weight: 400;
//   color: $dark10;
//   margin-bottom: 0;
// }

// .view-data {
//   font-size: 14px;
//   color: $dark14;
//   font-weight: 500;
// }

// .flex-1{
//   flex: 1;
// }


// .checkbox_container {
//   display: flex;
//   flex-direction: row-reverse;
//   align-items: center;
//   justify-content: center;
//   gap: 10px;
//   width: min-content;
//   position: relative;
//   label {
//     width: max-content;
//     margin-bottom: 3px;
//     font-size: 14px !important;
//     font-weight: 500 !important;
//     color: #1A1D1F;
//     display: flex;

//   }
//   input {
//     width: 20px;
//     height: 20px;
//   }
// }

// .MuiDataGrid-filler{
//   display: none !important;
// }

// .MuiDataGrid-cell {
//   text-overflow: ellipsis;
//   overflow: hidden;
//   text-transform: capitalize;
//   div{
//     text-overflow: ellipsis;
//     overflow: hidden;
//     text-transform: capitalize;
//   }
// }

// .no_capitalize{
//   text-transform: none !important;
// }
// h1,h2,h3,h4,h5,h6{
//   color: #1A1D1F !important;
// }
// .custome{
//   margin-left: 10px;
// }

// .fw-semi{
//   font-weight: 600;
// }
// .required-field::after {
//   content: "*";
//   color: $danger;
//   padding-left: 3px;
// }

@import 'Typography.scss';
@import 'inputs.scss';
@import 'variables.scss';
@import 'button.scss';
@import 'anchor.scss';

//common
.HeadingStyle {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: $font-Instrument;
  color: $dark !important;
}
.HeadingSubStyle{
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  font-family: $font-Instrument;
}
.addHeaderStyle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1B242C !important;
}
.headingSearchWrap {
  display: flex;
  justify-content: space-between;
}
.valueStyle {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-Instrument;
  color: #1b242c;
}
.searchButton {
  display: flex;
  align-items: center;
  gap: 20px;
}

.buttonWrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.resetAdd {
  display: flex;
  gap: 20px;
}

// Toast React Hot Toast Customization
.go2344853693:after {
  border-color: #1e273b !important ;
}

.go2534082608:after,
.go2534082608:before {
  background: rgb(190, 15, 0) !important;
}

.go2344853693:after {
  border-color: #83bf6e !important;
}

.go2344853693,
.go2534082608 {
  background-color: #fff !important;
}

::-moz-selection {
  /* Code for Firefox */
}

::selection {
}

//view
.view-label {
  font-size: 12px;
  font-weight: 400;
  color: $dark10;
  margin-bottom: 0;
}

.view-data {
  font-size: 14px;
  color: $dark14;
  font-weight: 500;
}

.flex-1{
  flex: 1;
}


.checkbox_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: min-content;
  position: relative;
  label {
    width: max-content;
    margin-bottom: 3px;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1B242C;
    display: flex;

  }
  input {
    width: 20px;
    height: 20px;
  }
}

.MuiDataGrid-filler{
  display: none !important;
}

.MuiDataGrid-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  div{
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    white-space: pre;
  }
}

.required-field::after {
  content: "*";
  color: $danger;
  padding-left: 3px;
}

.no_capitalize{
  text-transform: none !important;
}
h1,h2,h3,h4,h5,h6{
  color: #1B242C !important;
}
.custome{
  margin-left: 10px;
}

.fw-semi{
  font-weight: 600;
}

.pre{
  white-space: pre;
}

div:title{
  text-transform: capitalize;
}

.fixed-part{
  
}

.open-header-img{
  max-width: 190px;
  min-width: 100px;
  max-height: 60px;
  min-height: 60px;
  aspect-ratio: 4/1;
  object-fit: contain;
  object-position: center;
}
button:is(.padinng-increase-button){
  padding: 6px 32.5px !important;
}
.none-button-block{
  display: block;
}
.none-button-responsive-add{
  display: none;
}

.MuiDataGrid-cell,.MuiDataGrid-columnHeader{
&:focus{
  outline: none !important;
}
}


@media  (max-width: 768px) {
  .none-button-responsive{
    display: none;
  }
  .none-button-responsive-add{
    display: block;
  }
}

@media (min-width: 200px) and (max-width: 900px){
  .open-header-img{
    max-width: 160px;
    min-width: 100px;
    max-height: 50px;
    min-height: 50px;
    aspect-ratio: 4/1;
    object-fit: contain;
    object-position: left center;
  }
}

.reduce_dropdown_height{
  .select__control{
    min-height: auto !important;
    padding: 5px 10px;

    .select__control--menu-is-open{
      min-height: auto !important;
      padding: 5px 10px;
    }
  }
}