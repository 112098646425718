// @import '../../../assets/styles/variables.scss';
// .sidebar {
//   width: 330px !important;

//   display: flex;
//   flex-direction: column;
//   transition: width 0.6s ease;
//   background-color: $dark;
//   height: 100vh;
//   text-decoration: none; // Remove underlines from the sidebar
//   position: relative;

//   .header {
//     padding: 1.5rem 0 1.5rem 1.5rem;
//     align-items: center;
//     display: flex;
//     cursor: pointer;
//     .open-header-img {
//       height: 54px;
//       width: 214px;
//       border-radius: 8px;
  
//     }

//     .closed-header-img {
//       height: 21px;
//     }

//     .drawer-div {
//       background-color: $primary;
//       height: 28px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 12px;
//       cursor: pointer;
//       border-radius: 4px 0 0 4px;
//       z-index: 3;

//       position: absolute;
//       right: 0;

//       svg {
//         fill: $white;
//       }

//       &.closed {
//         scale: -1;
//         right: -12px;
//       }
//     }
//   }

//   .active-side {
//     color: $white !important;
//     background: $primary;
//     border-radius: 6px;
//   }
//   .sidebar-link {
//     span {
//       color: $side_grey;
//       height: 22.5px;
//       font-weight: 400;
//     }
//     path {
//       fill: $side_grey;
//     }
//   }

//   .ul {
//     list-style-type: none;
//     padding: 0;
//     margin: 0 0 0 12px;
//     overflow-y: auto;
//     overflow-x: hidden;

//     padding-right: 12px;
//     min-height: calc(100vh - 144px);

//     li {
//       list-style: none;
//       margin-bottom: 4px;
//       padding: 10px;
//       min-height: 22.5px;

//       a {
//         text-decoration: none;
//         color: $dark; // Set the text color for links
//         transition: color 0.3s ease; // Smooth transition for color change
//         font-size: 15px;
//         font-weight: 500;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         i {
//           display: flex;
//           width: 18px;
//           margin-right: 8px;
//         }
//         &:hover {
//           color: $white;
//           path {
//             fill: $white;
//             transition: 0.3s ease;
//           }
//         }
//       }
//       .sidebar-active-link {
//         &:hover {
//           path {
//             fill: $white;
//           }
//         }
//       }
//     }
//   }

//   .ul::-webkit-scrollbar {
//     width: 5px; /* Width of the scrollbar */
//   }

//   .ul::-webkit-scrollbar-thumb {
//     background: #c0c4c9; /* Color of the scrollbar thumb */
//     border-radius: 6px; /* Roundness of the scrollbar thumb */
//   }

//   .ul::-webkit-scrollbar-thumb:hover {
//     background: #555; /* Color of the scrollbar thumb when hovered */
//   }

//   &.closed {
//     width: 4rem !important;
//   }
// }

// .closed {
//   .header {
//     padding: 1.3rem;
//   }

//   .profile {
//     background-color: transparent;
//     transition: ease 0.3s;
//     padding: 16px 0;
//   }
// }

// .sidebar-active-link {
//   span {
//     font-weight: 500 !important;
//     color: $white !important;
//     transition: 0.3s ease;
//   }
//   path {
//     fill: $white !important;
//     transition: 0.2s ease;
//   }
// }

// .grey-fill-saved {
//   fill: #4e5660;
// }

// .profile {
//   height: 74px;
//   background-color: #464c61;
//   margin: 12px 16px;
//   border-radius: 8px;
//   justify-content: space-between;
//   display: flex;
//   align-items: center;
//   padding: 6px 16px;
//   cursor: pointer;
//   font-weight: 500;
//   .profile-span {
//     margin-top: 2px;
//     color: $side_grey;
//   }

//   svg {
//     fill: $side_grey;
//   }
// }


// .Dropdowm_switching_console{
//   position: fixed;
//   bottom: 103px;
//   left: 17px;
//   width: 87%;
//   background: none;
//   .select__control{
//     background: none;
//     color: #dee3e7;
//   }
//   .select__single-value{
//     color: #dee3e7;
//     padding-left: 10px;
//   }
//   .select__indicator{
//     rotate: 180deg;
//     svg{
//     fill: #dee3e7;
//     margin-left: 16px;
//     }
//   }
// }

@import '../../../assets/styles/variables.scss';
.sidebar {
  width: 250px !important;

  display: flex;
  flex-direction: column;
  transition: width 0.6s ease;
  background-color: $dark;
  height: 100vh;
  text-decoration: none;
  position: relative;

  .header {
    padding: 1.5rem 0 1.5rem 1.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .open-header-img {
      // height: 23px;
    }

    .closed-header-img {
      height: 21px;
    }

    .drawer-div {
      background-color: $primary;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      cursor: pointer;
      border-radius: 4px 0 0 4px;
      z-index: 3;

      position: absolute;
      right: 0;

      svg {
        fill: $white;
      }

      &.closed {
        scale: -1;
        right: -12px;
      }
    }
  }

  .active-side {
    color: $white !important;
    background: $primary;
    border-radius: 6px;
  }
  .sidebar-link {
    span {
      color: $side_grey;
      height: 22.5px;
      font-weight: 400;
    }
    path {
      fill: $side_grey;
    }
  }

  .sub-menu .sidebar-link {
    span {
      font-size: 13px ;
      height: 20px;
    }
  }

  .ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 12px;
    overflow-y: auto;
    overflow-x: hidden;

    padding-right: 12px;
    min-height: calc(100vh - 88px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    ul{
      padding-left: 0;
    }

    li {
      list-style: none;
      margin-bottom: 4px;
      padding: 10px;
      min-height: 22.5px;

      a {
        text-decoration: none;
        color: $dark; // Set the text color for links
        transition: color 0.3s ease; // Smooth transition for color change
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          display: flex;
          width: 18px;
          margin-right: 8px;
        }
        &:hover {
          color: $white;
          path {
            fill: $white;
            transition: 0.3s ease;
          }
        }
      }
      .sidebar-active-link {
        &:hover {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .ul::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }

  .ul::-webkit-scrollbar-thumb {
    background: #c0c4c9; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Roundness of the scrollbar thumb */
  }

  .ul::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
  }

  &.closed {
    width: 4rem !important;
  }
}

.closed {
  .header {
    padding: 1.3rem;
  }

  .profile {
    background-color: transparent;
    transition: ease 0.3s;
    padding: 16px 5px;
    bottom: 0;
    height: auto;
  }
}
.powered-by {
  font-size: 15px; /* Large font size */
  font-weight: bold; /* Bold text */
  color: #ffffff; /* White text */
  // text-transform: uppercase; /* All caps for emphasis */
  // background: linear-gradient(45deg, #0078d7, #00c4ff); /* Gradient background */
  padding: 0 20px 3px 20px; /* Add padding for spacing */
  border-radius: 10px; /* Rounded corners */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Text shadow for depth */
  // box-shadow: 0 4px 15px rgba(0, 120, 215, 0.5); /* Glow effect */
  display: flex; /* Ensure proper alignment */
  // animation: glow 1.5s infinite alternate; /* Glow animation */
  align-items: center;
  justify-content: center;
  svg{
  margin-bottom: 5px;
  }
}
.powered-by-span{
  color: #ffffff;
  font-size: 12px; /* Large font size */
  font-weight: bold;
  margin-right: 4px;
  display: flex;
  gap: 10px;
  margin-top: 1px;
}
/* Keyframes for glow animation */
@keyframes glow {
  from {
      box-shadow: 0 4px 15px rgba(0, 120, 215, 0.5);
  }
  to {
      box-shadow: 0 4px 25px rgba(0, 196, 255, 0.8);
  }
}


.sidebar-active-link {
  span {
    font-weight: 500 !important;
    color: $white !important;
    transition: 0.3s ease;
  }
  path {
    fill: $white !important;
    transition: 0.2s ease;
  }
}

.grey-fill-saved {
  fill: #4e5660;
}

.profile {
  height: 74px;
  background-color: #464c61;
  // margin: 4px 10px;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  text-overflow: ellipsis;
  .profile-span {
    margin-top: 2px;
    color: $side_grey;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 105px;
  }

  svg {
    fill: $side_grey;
  }
}

.Dropdowm_switching_console{
  position: fixed;
  // bottom: 14px;
    width: 100%;
    // margin: 4px 10px;
  background: none;
  .select__control{
    padding: 6px 16px;
    background: none;
    color: #dee3e7;
  }
  
  
  .select__control--menu-is-open{
    .select__indicator{
      scale: -1;
    }

    
  }
  .select__single-value  {
    color: #dee3e7;
    font-size: 13px;
  }
  .select__indicator{
    svg{
    fill: #dee3e7;
    }
  }
}
.profile{
  position: relative;
  // bottom: 70px;/
  height: 45px;
  // bottom: 0px !important;
}

// .adjust-up-drop-no{
//   bottom: 70px !important;
// }
// .adjust-down-drop{
//   bottom: 70px;
// }
// .adjust-up-drop-yes{
//   bottom: 180px !important;
// }

.sidebar{
  .input-placeholder-small ,.active{
    color: #dee3e7 !important;
  }
}


.sidebar-link{
  .count-sidebar{
    background: white;
    color: #052c65 !important;
    padding: 5px 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-weight: 500 !important;
  }
}
.sidebar-active-link{
  .count-sidebar{
    background: white !important;
    padding: 5px 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-weight: 500 !important;
  }
}