@media (max-width: 600px) {   
    .widgets{
      .info-card{
       min-width: 48% !important;
       max-width: 48% !important;
      }
    }
    .scale-down-dropdown{
        scale: 0.8;
        transform-origin: right;
    }
   }
   

   @media (max-width: 480px) {   
    .widgets{
      .info-card{
       min-width: 45% !important;
       max-width: 45% !important;
      }
      .info-card-detail .title{
        min-width: auto;
      }
    }
   }
   @media (min-width: 600px) and (max-width:900px) {   
    .widgets{
      .info-card{
       min-width: 48% !important;
       max-width: 48% !important;
      }
    }
   }
