@import '../../../assets/styles/variables.scss';

// Date Picker
.MuiInputBase-root,
.MuiOutlinedInput-root {
  background-color: white !important;
  height: 46px;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  border: 1px solid #dee3e7 !important;
  &:focus {
    border: none !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  color: #1a1d1f;
}

.calender-input-component {
  ::placeholder {
    color: #1b242c !important;
    font-weight: 400;
  }

  .Mui-disabled {
    -webkit-text-fill-color: #1a1d1f !important;

    fill: #1a1d1f !important;
  }
}

.calender-input-component,
.error-container {
  display: contents;
  .MuiOutlinedInput-input:not(:placeholder-shown) {
    padding-bottom: 2px !important;
  }
}

.calender-input-component:focus-within {
  outline: none;
  display: grid;
  overflow: hidden;
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--Brand-Color, #146ef5);
  input {
    &:focus {
      border: none;
      background-color: none;
    }
  }
  .MuiInputBase-root,
  .MuiOutlinedInput-root {
    border: none !important;
  }
}

// .calender-input-component:focus-within,
.error-container:focus-within {
  background-color: #c5343412;
  border: 1px solid $danger;
  outline: none;
  display: grid;
  overflow: hidden;
  border-radius: 4px;

  input {
    &:focus {
      border: none;
      background-color: none;
    }
  }

  div {
    background: $white !important;
  }
}

.error-container {
  .MuiFormControl-root{
    border: 1px solid var(--Red, #c53434) !important;
    border-radius: 4px ;
}
  .MuiInputBase-root {
    border: none;
    overflow: hidden;
    background-color: #c5343412 !important;
    // border: 1px solid var(--Red, #c53434) !important;
    border: none !important;
    input {
      background-color: #c5343400;
      border-radius: 0;
    }
  }
}

.calender-input-component {
  input {
    font-weight: 500 !important;
    color: $dark !important;
  }

  button {
    color: $dark;
    width: 45px !important;
  }

  :first-child {
    width: 100%;
  }
}
.calender-input-component:focus-within {
  .trydate {
    position: absolute;
    top: 0px;
    left: 15px;
    font-size: 12px !important;
  }
}

.trydate {
  position: absolute;
  top: 2px;
  left: 15px;
  font-size: 12px;
}

.place-holder-center {
  position: absolute;
  top: 15px;
  left: 15px;
  transition: 0.2s ease all;
  font-size: 0.85rem;
}

.calender-input-component{
  .active{
    font-size: 12px !important;
  }
}