@import "../../../assets/styles/variables.scss";

.custom-filter-container {
  position: relative;
  /* Set to relative to control the positioning of its children */
  cursor: pointer;
}

.filter-header {
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  .header {
    color: $dark;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;

    i {
      font-size: 14px;
    }
  }
}

.filter-box {
  top: calc(100% + 12px);
  display: flex;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--30, #efefef);
  flex-direction: column;
  z-index: 100;
  right: 0;
}

.filter-data-box {
  display: flex;
  flex-direction: row;
  // min-height: 300px;
  max-height: 300px;
  /* Set your desired maximum height */
  overflow-y: auto;
  /* Add this to enable scrolling when the content exceeds max-height */
}

.filter-key-box {
  display: flex;
  flex-direction: column;
  min-width: 170px;
  border-right: 1px solid #efefef;
  background-color: #F5F7F9;
}

.filter-key {
  padding: 8px 10px;
  // justify-content: center;
  align-items: center;
  color: $dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.filter-key:hover,
.filter-key:focus,
.filter-key:active,
.active-key {
  background-color: #FFFFFF;
  border-left: 3px solid #146EF5;
}

.filters-column {
  padding: 15px;
  // padding-right: 70px;
  padding-top: 0;

  font-family: $font-Instrument;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 170px;
  width: 240px;
  min-height: 240px;
  height: 100%;
}

label {
  font-size: 14px !important;
}

.dot-primary {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 7px;
  background-color: $primary;
  display: inline-block;
}

@media (min-width:200px) and (max-width:480px){
  .filter-mobile-container{
    .filter-box{
      scale: 0.7;
      top: calc(100% - 41px);
      // right: -57px;
    }

  }
}

// .active-filter{
// //   border: 1px solid $primary !important;
// background: var(--Dark-4, #F1F3F5) !important;


// }
button:is(.active-filter) {
  background: var(--Dark-4, #F1F3F5) !important;
  position: relative;
  width: 105px;
  padding: 8px 25px 8px 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-filter::before {
  content: '';
  position: absolute;
  top: 45%;
  right: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 7px;
  background-color: #146EF5;
}


.active-filter, .active-filter:hover{
  // border: 1px solid $primary !important;
  // background-color: #F1F3F5 !important;
}
button:is(.filter-contain-btn){
  border: 1px solid #555F6D ;
  border-radius: 6px ;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 16px;
  background: none;
  &:hover{
    background: var(--Dark-4, #F1F3F5);
    color: #1B242C;
  }
}
.currency-input-container{
  position: relative;
  input{
    height: 40px;
    padding: 2px;
  }
  .currency-input-content{
    position: absolute;
    width: 30px;
    // height: 100%;
    z-index: 1;
    height: 40px;
    right: 0;
    top: 0;
    background: rgb(239, 239, 239);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;


  }
}