.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 1rem;

  .widgets,
  .quick-links {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .widgets{
  svg,
  path {
    fill: #111112;
    width: 24px;
    height: 24px;
  }
}

  .quick-links {
    margin: 1.5rem 0;
    gap: 1rem;
    align-items: start;
    justify-content: center;

    svg,
    path {
    fill: #111112;

      width: 20px;
      height: 20px;
    }
  }

  .quick-link {
    display: flex;
    align-items: center;

    flex-direction: column;
    justify-content: space-between;
    min-width: 95px;
    max-width: 100px;

    .description {
      font-weight: 600;
      text-align: center;
      margin-top: 0.5rem;
      cursor: pointer;
      font-size: 13px;
    }

    .description:hover {
      animation: zoom 0.4s ease-in-out;
    }

    @keyframes zoom {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2); /* Slightly enlarge */
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .common {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 1rem;
  }

  .main {
    h1 {
      line-height: normal;
    }
    width: 100%;
    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    display: flex;
    gap: 24px;
  }

  /* Style for the first child of .main */
  .left {
    width: 71%;
  }

  /* Style for the last child of .main */
  .right {
    width: 29%;
  }
}

@media (max-width: 1550px) {
 .quick-links{
  gap: 2px !important;
  row-gap: 15px !important;
 }

 .widgets{
   .info-card{
    width: 222px;
   }
 }
}

.date-picker-bar-chart-left{
width: 85px;
scale: 0.80;
transform-origin: top left;
background-color: white;
z-index: 1;

  // transform-origin: top left;
}
.date-picker-bar-chart-right{
  scale: 0.78;
  transform-origin: top right;
  .rs-picker-toggle-wrapper{
  width: 220px !important;

  transform-origin: top right;
  // height: 40px !important;
  }
  // scale: 0.73;
  // transform-origin: top right;
}
.dropdown2{
  border:1px solid  hsl(0, 0%, 80%);
  border-radius: 4px;
  gap: 10px;
  min-width: 100px;

}
.dropdown2-box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 10px;
}
.dropdown2-menu{
  margin-top: 3px;
  max-height: 200px;
  top: 5px !important;
  overflow: scroll;
}

.dropdown-item2.selected {
  background-color: #F1F3F5 !important; 
  color: white;
}

.dropdown-item2:active {
  background-color: #F1F3F5 !important;
  color: white;
}