 .card-container {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 100%;
    max-width: 400px;
  }
  
  .card-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }
  
  .card-action-icon {
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .card-item {
    display: flex;
    justify-content: space-between;
  }
  
  .card-label {
    font-weight: bold;
    color: #555;
  }
  
  .card-value {
    color: #333;
  }
  
  .text-warning {
    color: #f0ad4e;
  }
  
  .text-success {
    color: #5cb85c;
  }
  
  .text-error {
    color: #d9534f;
  }
  
  .text-dark {
    color: #333;
  }

  @media (min-width: 200px) and (max-width: 700px) {
    .card-list{
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-top: 12px;
    }
    .card-container{
      width: 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 700px) and (max-width: 900px) {
    .card-list{
   display: flex;
   flex-direction: row;
   width: 100%;
   gap: 2%;
   flex-wrap: wrap;
   margin-top: 12px;
    }
    .card-container{
      width: 48%;
      max-width: 48%;
    }
  }